<template>
  <div class="main-container">
    <label class="item-label mt-5" v-if="label">{{ label }}</label>
    <div class="textarea-container">
      <div class="tools">
        <div style="flex: 1"></div>
        <div class="item">
          <fa icon="copy" class="copy" @click="copyToClipboard"/>
        </div>
        <div class="item" v-if="!withinLevel">
          <div class="info" >
            <fa icon="info-circle" />
          </div>
          <div class="result" v-if="!withinLevel">
            <div v-for="r in cefrLevelsResultArray" :key="r.name">
              <div class="level">{{ r.name }}: </div>
              <div class="value">{{ r.value }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="grow-wrap">
        <validation-provider rules="required">
          <textarea
            class="item-textarea"
            :class="{ 'red-border': !withinLevel }"
            :rows="rows || 7"
            ref="textarea"
            type="text"
            v-model="text"
            @change="change"
            :placeholder="placeholder"
            :placeholderOnFocus="placeholderOnFocus"/>
        </validation-provider>
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import * as clipboardy from "clipboardy";
export default {
  name: "Textarea",
  props: {
    value: String,
    label: String,
    rows: Number,
    placeholder: String,
    placeholderOnFocus: String,
    cefrLevel: null,
    maxlength: {
      type: Number,
      default: -1
    },
    parser: Function,
  },
  data() {
    return {
      text: this.value,
      debounce: null,
      cefrLevelsResultArray: [],
      withinLevel: true,
      showCefrLevel: false,
      id: uuidv4()
    };
  },
  methods: {
    change() {
      if (this.parser) {
        this.text = this.parser(this.text);
      }
    },
    copyToClipboard() {
      clipboardy.write(this.text);
    }
  },
  watch: {
    text() {
      this.$emit("input", this.text);
      this.$store.commit('item/setWordCount', this.text);

      // Automatically resize the text area
      this.$refs.textarea.parentNode.dataset.replicatedValue = this.text;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  max-width: 550pt;
  height: fit-content;
  margin-bottom: 20pt;
  box-sizing: border-box;
  .textarea-container {
    position: relative;
    display: block;
    margin: 0 auto;
    padding: 9pt;
    transform: translateZ(0);
    -webkit-text-size-adjust: none;
    background-color: $control-background;
    border-radius: 4pt;
    width: 100%;
    box-sizing: border-box;
    height: fit-content;
    transition: all ease-in-out 200ms;
    .tools {
      width: fit-content;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      .item {
        margin: auto;
        flex: 0;
        margin-left: 11pt;
        .copy {
          cursor: pointer;
          opacity: 0.4;
        }
        .copy:hover {
          opacity: 0.6;
        }
      }
    }
  }

}
</style>
