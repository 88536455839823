<template>
  <div class="textbox-container">
    <label v-if="label" class="item-label">{{ label }}</label>
    <validation-provider rules="required" v-slot="{ errors }">
      <input
        class="item-input"
        :disabled="disabled"
        type="text"
        v-model="valueObject"
        @input="$emit('input', $event.target.value)"
        v-bind:class="{ 'title' : isTitle == true, 'red-border': !withinLevel }"
        :placeholder="placeholder"
        :placeholderOnFocus="placeholderOnFocus">

      <div class="info" v-if="!withinLevel">
        <fa icon="info-circle" />
      </div>
      <div class="result" v-if="!withinLevel">
        {{ errors[0] }}
        <div v-for="r in cefrLevelsResultArray" :key="r.name">
          <div class="level">{{ r.name }}: </div>
          <div class="value">{{ r.value }}</div>
        </div>
      </div>
    </validation-provider>
  </div>

</template>

<script>
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'TextBox',
  props: {
    label: String,
    placeholder: String,
    placeholderOnFocus: String,
    value: String,
    cefrLevel: null,
    isTitle:{
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: -1
    },
    ignore: Boolean,
    last:Boolean,
    disabled:Boolean
  },
  data () {
    return {
      valueObject: this.value,
      debounce: null,
      cefrLevelsResultArray: [],
      withinLevel : true,
      showCefrLevel: false,
      id: uuidv4(),
    };
  },
  computed: {
    valueComputed: {
      get: () => {
        return this.value
      },
      set: (value) => {
        this.$emit('valuechange', value)
      }
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.valueObject = newVal
      }
    },
  },
}
</script>


<style lang="scss" scoped>

.textbox-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  max-width: 550pt;
}

</style>