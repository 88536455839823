<template>
    <div>
      <TextBox
        :cefrLevel="payload.cefr_level"
        :isTitle="true"
        :label="'Title'"
        placeholder="Title"
        v-model="title"
      />
      <Textarea
        :cefrLevel="payload.cefr_level"
        :rows="13"
        :label="'Content text'"
        v-model="long_reading_text"
        placeholder="Full paragraph"
        placeholderOnFocus="Start typing"
      />

      <label>Questions</label><br>

      <OptionsRadio
        :number="1"
        :hideQuestionLabel="true"
        :cefrLevel="payload.cefr_level"
        v-model="questions[0]"/>
      <br>
      <br>

      <OptionsRadio
        :number="2"
        :hideQuestionLabel="true"
        :showLegend="false"
        :cefrLevel="payload.cefr_level"
        v-model="questions[1]"/>
      <br>
      <br>

      <OptionsRadio
        :number="3"
        :hideQuestionLabel="true"
        :showLegend="false"
        :cefrLevel="payload.cefr_level"
        :last="true"
        v-model="questions[2]"/>
      <br>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextBox from '../../../components/item/TextBox'
import Textarea from '../../../components/item/Textarea'
import OptionsRadio from '../../../components/item/OptionsRadio'
import { v4 as uuidv4 } from 'uuid'

export default {
    data: () => ({
        title:'',
        long_reading_text:'',
        questions: [
          {
            position: 1,
            question: '',
            question_id: uuidv4(),
            solution: [''],
            question_options:[
              {
                  value:'',
                  option_id: uuidv4()
              },
              {
                  value:'',
                  option_id: uuidv4()
              },
              {
                  value:'',
                  option_id: uuidv4()
              }
            ]
          },
          {
            position: 2,
            question: '',
            question_id: uuidv4(),
            solution: [''],
            question_options:[
              {
                  value:'',
                  option_id: uuidv4()
              },
              {
                  value:'',
                  option_id: uuidv4()
              },
              {
                  value:'',
                  option_id: uuidv4()
              }
            ]
          },
          {
            position: 3,
            question: '',
            question_id: uuidv4(),
            solution: [''],
            question_options:[
              {
                  value:'',
                  option_id: uuidv4()
              },
              {
                  value:'',
                  option_id: uuidv4()
              },
              {
                  value:'',
                  option_id: uuidv4()
              }
            ]
          }
        ]
    }),
    computed: {
      ...mapState('item', ['payload', 'isUpdate']),
    },
    methods:{
        onSubmit() {
            // When creating an item, set each question's solution[id] to be the option_id of that question's first question_option
            if (!this.isUpdate) this.questions.forEach(question => question.solution[0] = question.question_options[0].option_id)

            const data = {
                template_type: 'MCQ_TRIPLE_LONG_READING',
                static_text: 'Answer 3 questions about the text.',
                title:this.title,
                long_reading_text:this.long_reading_text,
                questions: this.questions
            }
            this.$store.commit('item/setPayload', data)
        },
    },
    beforeMount(){
      if (this.isUpdate && Object.keys(this.payload).length) {
        this.title = this.payload.title || ''
        this.long_reading_text = this.payload.long_text

        // For each question, make sure that the solution is first in the list of question options
        this.questions = this.payload.questions.map(({ question, question_id, question_options, solution, position }) => {
          const solutionIndex = question_options.findIndex(option => option.option_id === solution[0]);
          const solutionOption = question_options[solutionIndex];
          question_options.splice(solutionIndex, 1);
          question_options.unshift(solutionOption);

          return {
            question,
            question_id,
            question_options,
            solution,
            position,
          }
        }).sort((a, b) => a.position - b.position)
      }
    },
    components:{
      TextBox,
      Textarea,
      OptionsRadio
    }
}

</script>
