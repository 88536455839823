<template>
    <div class="main-container">
        <div class="number" v-if="number > 0">
            {{ number }}
        </div>
        <div class="container">
            <TextBox 
                v-if="!noQuestion"
                :label="hideQuestionLabel ? '' : 'Question'"
                v-model="value.question"
                :cefrLevel="cefrLevel"
                :placeholder="'Insert Question here'"/>
            <div class="helper">
                <label class="item-label">Options</label>
                <div v-if="showLegend" class="legend">
                    <div class="item">
                        <div class="solution"/>
                        <label>Solution</label>
                    </div>
                    <div class="item">
                        <div class="distractor"/>
                        <label>Distractor</label>
                    </div>
                </div>
            </div>
            <div class="options">
                <div class="option">
                    <div class="solution"></div>
                    <TextBox 
                        v-model="value.question_options[0].value"
                        :cefrLevel="cefrLevel"
                        class="textbox"
                        :isTitle="true"
                        :placeholder="'Insert Solution here'"/>
                </div>
            </div>
            <div class="options">
                <div class="option">
                    <div class="distractor"></div>
                    <TextBox 
                        v-model="value.question_options[1].value"
                        :cefrLevel="cefrLevel"
                        class="textbox"
                        :placeholder="'Insert Distractor here'"/>
                </div>
            </div>
            <div class="options">
                <div class="option">
                    <div class="distractor"></div>
                    <TextBox 
                        v-model="value.question_options[2].value"
                        :cefrLevel="cefrLevel"
                        class="textbox"
                        :placeholder="'Insert Distractor here'"
                        :last="last"/>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import TextBox from '../item/TextBox'

export default {
    name:'OptionsRadio',
    props: {
        title:String,
        label: String,
        placeholder: String,
        placeholderOnFocus: String,
        value: Object,
        last:Boolean,
        cefrLevel: null,
        maxlength: {
            type: Number,
            default: -1
        },
        number: {
            type: Number, 
            default: null
        },
        showLegend: {
            type: Boolean,
            default: true
        },
        noQuestion: {
            type: Boolean,
            default: false
        },
        hideQuestionLabel: {
            type: Boolean,
            default: false
        }
    },
    components: {
        TextBox
    }
}
</script>


<style lang="scss" scoped>


.main-container {
    position: relative;
    display: flex;
    max-width: 550pt;
    box-sizing: border-box;

    .number {
        position: relative;
        background: $navy-blue;
        height: 21pt;
        width: 23pt;
        border-radius: 30pt;
        text-align: center;
        color: white;
        margin-top: 13pt;
        margin-right: 10pt;
    }
}



.container  {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;

    .item {
        display: flex;

        div {
            margin: auto;
            width: 15pt;
            height: 15pt;
        }
        label {
            margin: auto;
            margin-left: 5pt;
            color: unset;
        }
    }
    .item:nth-child(1) {
        padding-right: 10pt;
    }

    .helper {
        display: flex;
        align-content: center;
        margin-top: 15pt;

        label {
            flex: 1;
        }

        .legend {
            display: flex;
            font-size: 0.7em;
            font-weight: 600;
        }
    }

    .options {
        .option {
            display: flex;
            padding-left: 10pt;
            margin-bottom: 5pt;
            height: 45pt;
            div {
                width: 17pt;
                height: 17pt;
                margin: auto;
            }
            .textbox {
                flex: 1;
                margin-left: 7pt;
                height: 35pt;
            }
        }
    }

    .distractor {
       background-color: $distractor;
       border-radius: 2.5pt;
    }

    .solution {
        background-color: $solution;
        border-radius: 20pt;
    }
}



</style>